import React from "react";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";

const PartPhotos = (props) => {
    // let serverName = props.uploadTarget;
    let photosPresent = false;    
    let counter = 0;
    let photosTaken = (
        <div>
            <div className="partFotoHeader">Partfotos</div>
            {Object.keys(props.partList).map((key, index) => {
                if (props.partList[key].taken) {
                    photosPresent = true;
                    counter++;
                    // return <li><a href={serverName+props.partList[key].filename} target='new'>{props.partList[key].name}</a></li>                
                    return <span className="partFotoEntry">{counter > 1 && ", "}{props.partList[key].name}</span>
                }
            })}        
        </div>
    );

    if (!photosPresent) {
        photosTaken = null;
    }
    return (
        photosTaken
    );
}

export default PartPhotos;