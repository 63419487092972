import React from "react";
import {Suspense, useState} from "react";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { useLoader, useThree } from "@react-three/fiber";
import * as THREE from "three";
import state from "./state/state";
import { Html } from "@react-three/drei/web";
import { useTexture} from "@react-three/drei";

import PartColors from "./PartColors";

import "./Model.css"

import CameraComponent from "./CameraComponent";


const Model = props => {
    // const [viewOption, setViewOption] = useState("model");

    const textureNames = [];
    const textureMap = [];
    Object.keys(props.partList).map((key, index) => {
        const map = props.partList[key].map != null && props.partList[key].map.length > 5 ? props.partList[key].map: false;
        if (map) {
            textureNames.push(key);
            textureMap.push(map);
        }
    });

    //console.log(textureNames, textureMap);
    // const {gl} = useThree();
    // // const logoTexture  = useTexture("/images/krug_logo_transparent.png");
    // const texture = useLoader( THREE.TextureLoader, "/images/krug_logo_transparent.png");
    // const formatted  = new THREE.WebGLCubeRenderTarget(texture.image.height).fromEquirectangularTexture(gl, texture);
    
    const setDefaultColors = (model) => {
        Object.keys(props.partList).map((key, index) => {
            // console.log(key);
            model.children.forEach(partMesh => {
                //images/krug_logo_transparent.png
                if (key === partMesh.name) {
                    
                    let textureMap = null;
                    let putTexture = false;
                    if (props.partList[key].map && props.partList[key].map.length > 5) {
                        putTexture = true;
                    }

                    let colorKey = props.partList[key].state === "default" ? props.partList[key].color : props.partList[key].state;

                    switch(props.partList[key].material) {
                        case "m":                            
                            partMesh.material = new THREE.MeshStandardMaterial( {
                                color: PartColors[colorKey],
                                metalness: 0.8,                                
                                roughness: 0.1                                
                            });
                        break;
                        case "g":
                            partMesh.material = new THREE.MeshBasicMaterial( { color: PartColors[colorKey] } );                                            
                        break;
                        case "b":
                            partMesh.material = new THREE.MeshStandardMaterial( {
                                color: PartColors[colorKey],
                                emissive: PartColors[colorKey],
                                emissiveIntensity: 5
                            });                            
                        break;
                        case "r":
                            partMesh.material = new THREE.MeshStandardMaterial( {
                                color: PartColors[colorKey],                                                                
                                roughness: 0.9
                            });    
                        break;
                        case "p":
                            partMesh.material = new THREE.MeshBasicMaterial( { color: PartColors[colorKey] } );                                            
                        break;
                        case "l":                            
                            partMesh.material = new THREE.MeshStandardMaterial( {
                                color: PartColors[colorKey],
                                metalness: 0.5,
                                roughness: 0.1
                            });                            
                        break;
                        default:
                            partMesh.material = new THREE.MeshBasicMaterial( { color: PartColors[colorKey] } );                                            
                    }
                      if (putTexture) {
                        // logoTexture.flipY = false;
                        // logoTexture.repeat = false;
                        // logoTexture.mapping = THREE.CubeRefractionMapping;
                        // logoTexture.encoding = THREE.LinearEncoding;                        
                        // formatted.setSize(10,10);
                        // texture.wrapS = THREE.ClampToEdgeWrapping;                        
                        // texture.wrapT = THREE.RepeatWrapping;                        
                        // partMesh.material.map = texture;                        
                        
                    }
                }
            });
        });
    };

    // const model = useFBX(props.path);
    const model = useLoader(FBXLoader, props.path);
    const [hovered, setHover] = useState(false)
    const [partName, setPartName] = useState(false)
    const [partPosition, setPartPosition] = useState(0)
    //console.log(model);
    //console.log(props.partList);
    
    setDefaultColors(model);

    const handleCarClicked = e => {        
        if (e.object) {
            const partName = props.partList[e.object.name] && props.partList[e.object.name].name !== "" ? props.partList[e.object.name].name : e.object.name;
            console.log("Clicked: "+props.viewOption);
            state.activeMesh = e.object.parent;
            props.setViewOption("camera", partName, e.object.name);
        }
      };

    const handlePartClicked = e => {        
        if (props.partList[e.object.name]) {
            const partName = props.partList[e.object.name] && props.partList[e.object.name].name !== "" ? props.partList[e.object.name].name : e.object.name;
            state.activeMesh = e.object;//.parent;
            setCurrentPartColor(e.object, "partClicked");
            setHover(true);
            setPartName(partName);    
        }
    };

    const setCurrentPartColor = (part, state) => {        
        props.changePartState(part.name, state);              
    }

    const handlePartPhoto = e => {       
        console.log("Clicked"); 
        console.log(state.activeMesh); 
        if (state.activeMesh) {
            const partName = props.partList[state.activeMesh.name] && props.partList[state.activeMesh.name].name !== "" ? props.partList[state.activeMesh.name].name : state.activeMesh.name;
            console.log("Foto click: "+partName);
            setHover(false);
            setPartName("");             
            props.setViewOption("camera", partName, state.activeMesh.name);   
        }
    };
    const handlePartPhotoCancel = e => {
        if (state.activeMesh) {            
            props.changePartState(state.activeMesh.name, "default");                      
        } 
        setHover(false);
        setPartName("");                                 
    };
  
    const onPointerEnter = e => {
        if (e.object) {
            e.object.active = true;            
            const partName = props.partList[e.object.name] && props.partList[e.object.name].name !== "" ? props.partList[e.object.name].name : e.object.name;
            setHover(true);
            setPartName(partName);            
            // e.object.material.color = new THREE.Color("red");
            // console.log("over: "+e.object.parent.name );
        }
    };
    
    const onPointerOver = e => {
        if (e.object) {
            const partName = props.partList[e.object.name] && props.partList[e.object.name].name !== "" ? props.partList[e.object.name].name : e.object.name;
            setHover(true);
            setPartName(partName);            
            // setPartPosition(`transform: translate3d(${e.opject.position.x}, 0, 0);`);
            // e.object.material.color = new THREE.Color("red");
            //console.log(e.object );
        }
    };

    const onPointerLeave = e => {
        if (e.object && e.object.active === true) {
            e.object.active = false;  
            setHover(false);      
            setPartName("");          
            // e.object.material.color = new THREE.Color("yellow");
        }
    };

    const scale = (object, value) => {
        object.scale.x = value;
        object.scale.y = value;
        object.scale.z = value;
    };

    const setColor = (object, color) => {
        //console.log(object.parent.children[37].material);//.material.color.r=0.9;
        // object.parent.children[37].mesh.material.setRGB(0.9,0.2,0.2);
        // object.parent.children[37].mesh.material.color.b=1;
        
        object.material.color.r = color.r;
        object.material.color.g = color.g;
        object.material.color.b = color.b;
    };

    return (
        <Suspense fallback={null}>            
            {/* <Dragable transformGroup_>        */}
                <primitive 
                    object={model} 
                    {...props}     
                    onClick={handlePartClicked}   
                    // onClick={handleCarClicked}   
                    // onPointerEnter={onPointerEnter}   
                    // onPointerLeave={onPointerLeave}   
                    // onPointerOver={onPointerOver}      
                    // onPointerDoubleClick={onPointerOver}      
                />
                {hovered && (
                    <Html scaleFactor={10}>
                        <div id="partInfoBox" className="partNameBox">
                            <div className="partNameBoxContent">
                                Ausgewählt:{partName}
                            </div>
                            <div style={{padding: "5px"}}>                        
                                <button 
                                style={{width: "45%"}}
                                className="btn btn-default btn-block_"
                                onClick={e => {
                                    e.stopPropagation();
                                    handlePartPhotoCancel()
                                }}
                                >Zurück</button>        
                                <button                                 
                                style={{width: "45%", marginRight: "10px"}}
                                className="btn btn-primary btn-block_"
                                onClick={e => {
                                    e.stopPropagation();
                                    handlePartPhoto(e)
                                }}
                                >Foto</button>                                                                    
                            </div>
                        </div>
                    </Html>
            )}
        </Suspense>     
    );
}
export default Model;