const PartColors = {
    "body" : "#F5F7F2",
    "glass": "#D8E4E0",
    "backlight": "#FFC2C2",
    "frontlight": "#EEDD82",
    "wheel": "#111111",
    "felge": "#A8A9AD",
    "rim": "#A8A9AD",    
    "grill": "#E6E6E6",
    "partSelected" : "#4674A9",
    "partTaken" : "#c0c0ff",
    "partClicked": "#B0CFFA"
};

export default PartColors;