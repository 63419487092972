import React from "react";

import "./PhotoPreview.css";

const photoPreview = (props) => {
    return (
        <div className="card previewBox">
            <div className="card-header previewHeader">
                {props.photoData.title}
            </div>            
            <div className="card-body previewImageBox">                
                <div></div>
                <img className="previewImage" onClick={() => props.changeView("main")}  src={props.photoData.image} alt={props.photoData.description} />                            
                <div></div>
            </div>                    
            <div className="previewDescription">
                {props.photoData.description}
            </div>
            <div className="card-footer previewControls">
                <button type="button" className="btn btn-primary btn-sm" onClick={() => props.changeView("main")}>
                    Zurück
                </button>                
            </div>
        </div>
    )
}

export default photoPreview;