import React from "react";
import axios from "axios";

const Uploader = (props) => {    
    
    // let data = new FormData();    
    // data.append('image', props.image);  

    const formData = new FormData();
    formData.append("action", "store");
    formData.append("systemID", props.systemID);
    formData.append("id", props.id);
    formData.append("key", props.assKey);
    formData.append("format", "png");
    formData.append("title", props.fileName);
    formData.append("filename", props.partKey);
    formData.append("svp", props.svp);
    formData.append("file", props.image); 

    let fileName = "";
    axios.post(props.path+"/upload.php", formData , {
        headers: { "X-Requested-Width": "XMLHttpRequest", "Access-Control-Allow-Origin": "*", "Content-Type" : "multipart/form-data;"}
    }) .then(res => {
        fileName = res.data.message;
        props.returnFileName = res.data.message;
    })
    .catch(err => console.log(err));            
};

export default Uploader;