import React from "react";

import PhotoBox from "./PhotoBox";
import "./PhotoList.css";

const photoList = (props) => { 
    let photoListTitle = null;
    if (props.type === "dam") {
        photoListTitle = "Schaden Fotos";
    } else if (props.type === "doc") {
        photoListTitle = "Sonstige Dokumente";        
    } else if (props.type === "vorschaden") {
        photoListTitle = "";
    }    

    let key = 1000;

    let addButton =  "";
    //if ( || props.type === "doc") {
    if (props.type === "dam" && props.damageDetailAddButton === true) {
        addButton = (            
        <div>
            <button key={key++} type="button" className="btn btn-warning bt-block" onClick={props.addDamageDetail}>Schaden Foto hinzufügen</button>
        </div>
        );
    }

    if (props.type === "doc" && props.documentAddButton === true) {
        addButton = (            
        <div>
            <button key={key++} type="button" className="btn btn-warning bt-block" onClick={props.addDocuments}>Dokument hinzufügen</button>
        </div>
        );
    }

    if (!props.moreButtons) {
        addButton = null;
    }

    let gridClassName = "";
    if (props.className) {
        gridClassName = props.className+"Grid";
    }


    let photoListBox = (
        <div>
            <div className="photoListBoxHeader">&nbsp;</div>
            {photoListTitle? <h3 className="photoListHeaderTitle">{photoListTitle}</h3> : ""}          
            <div className={"photoGrid "+gridClassName}>    
                        
                {props.photos.map((photo, index) => {                                        
                    console.log("Dokument: "+photo.fileName+" "+props.type+" "+photo.type);
                    
                    if ((props.type === "std" && photo.type === 0) 
                    || (props.type === "dam" && photo.type === 1 && props.damageDetailPhotos > 0) 
                    || (props.type === "bank" && photo.type === 3) 
                    || (props.type === "vorschaden" && photo.type === 4)
                    || (props.type === "doc" && photo.type === 2 && props.documents > 0)) { 
                        return (
                            <PhotoBox 
                                assKey={props.assKey}
                                uploadTarget={props.uploadTarget}        
                                contractData={props.contractData}                
                                type={props.type} 
                                key={photo.id}
                                id={photo.id}
                                format={photo.format}
                                svp={props.contractData.svp}
                                title={photo.title}
                                fileName={photo.fileName} 
                                fileMaxSizeMultiPhoto={props.fileMaxSizeMultiPhoto}
                                fileMaxSizeMultiVideo={props.fileMaxSizeMultiVideo}
                                image={photo.image} 
                                fileData={photo.fileData}
                                required={photo.required}
                                uploaded={photo.uploaded}
                                metadata={photo.metadata}
                                icon={photo.icon} 
                                onError={props.onError}
                                errorMessage={props.errorMessage}
                                generalError={props.generalError}  
                                uploadError={photo.uploadError}                                
                                description={photo.description} 
                                takePhotoHandler={props.takePhotoHandler}
                                skipbtn={photo.skipbtn}
                                onSkipPhoto={props.onSkipPhoto}
                                openPhoto={props.openPhoto}
                                changeView={props.changeView}
                                showPreview={props.showPreview}
                                deletePhoto={props.deletePhoto}
                                className={props.className}  
                                
                                isUploading={props.isUploading}
                                onUploading={props.onUploading} 
                            />
                            );
                        } else {
                            return null;
                        }
                    })                
                }
                {addButton}
            </div>
        </div>
    );

    if ((props.type === "dam" && props.damageDetailPhotos === 0) 
        || (props.type === "doc" && props.documents === 0)) {
            photoListBox = null;
    }

    return (   
        photoListBox
    );
}

export default photoList;