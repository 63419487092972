import React from "react";

import PhotoList from "../Photo/PhotoList";
import Controls from "./Controls";
import ClickableCar from "../ClickableCar/ClickableCar";

import "./Assignement.css"

const assignement = (props) => {    
    
    let photoBoxes = (
        <div>
            <PhotoList 
                systemID={props.systemID}
                svp={props.svp}
                type="std"
                assKey={props.assKey}
                uploadTarget={props.uploadTarget}
                contractData={props.contractData} 
                photos={props.standardPhotos}
                damageDetailPhotos={props.damageDetailPhotos}
                documents={props.documents} 
                takePhotoHandler={props.takePhotoHandler}
                onSkipPhoto={props.onSkipPhoto}
                changeView={props.changeView}
                openPhoto={props.openPhoto}
                showPreview={props.showPreview}
                deletePhoto={props.deletePhoto}
                className={null}
                moreButtons={props.moreButtons}
                isUploading={props.isUploading}
                onUploading={props.onUploading}
                fileMaxSizeMultiPhoto={props.fileMaxSizeMultiPhoto}
                fileMaxSizeMultiVideo={props.fileMaxSizeMultiVideo}
                onError={props.onError}   
                errorMessage={props.errorMessage}
                generalError={props.generalError}                               
            />    
            {/* <div>
                <div className="photoListBoxHeader">&nbsp;</div>
                <ClickableCar 
                    uploadTarget={props.uploadTarget}
                    takePartPhotoHandler={props.takePartPhotoHandler}
                    changePartState={props.changePartState}
                    isUploading={props.isUploading}
                    onUploading={props.onUploading}
                    systemID={props.systemID}
                    assKey={props.assKey}         
                    svp={props.svp}           
                    partList={props.partList}
                />    
            </div> */}
            <PhotoList 
                systemID={props.systemID}
                svp={props.svp}
                type="dam"
                assKey={props.assKey}
                uploadTarget={props.uploadTarget}
                contractData={props.contractData} 
                photos={props.standardPhotos}
                damageDetailPhotos={props.damageDetailPhotos}
                documents={props.documents} 
                takePhotoHandler={props.takePhotoHandler}
                onSkipPhoto={props.onSkipPhoto}
                changeView={props.changeView}
                openPhoto={props.openPhoto}
                showPreview={props.showPreview}
                deletePhoto={props.deletePhoto}
                addDamageDetail={props.addDamageDetail}
                damageDetailAddButton={props.damageDetailAddButton}                        
                className={null}
                moreButtons={props.moreButtons}
                isUploading={props.isUploading}
                onUploading={props.onUploading}
                fileMaxSizeMultiPhoto={props.fileMaxSizeMultiPhoto}
                fileMaxSizeMultiVideo={props.fileMaxSizeMultiVideo}
                onError={props.onError}       
                errorMessage={props.errorMessage}
                generalError={props.generalError}                           
            />
            <PhotoList 
                systemID={props.systemID}
                svp={props.svp}
                type="doc"
                assKey={props.assKey}
                uploadTarget={props.uploadTarget}
                contractData={props.contractData} 
                photos={props.standardPhotos}
                damageDetailPhotos={props.damageDetailPhotos}
                documents={props.documents}       
                takePhotoHandler={props.takePhotoHandler}
                onSkipPhoto={props.onSkipPhoto}
                changeView={props.changeView}
                openPhoto={props.openPhoto}
                showPreview={props.showPreview}
                deletePhoto={props.deletePhoto}
                addDocuments={props.addDocuments}
                documentAddButton={props.documentAddButton}
                className={null}
                moreButtons={props.moreButtons}
                isUploading={props.isUploading}
                onUploading={props.onUploading}
                fileMaxSizeMultiPhoto={props.fileMaxSizeMultiPhoto}
                fileMaxSizeMultiVideo={props.fileMaxSizeMultiVideo}
                onError={props.onError}    
                errorMessage={props.errorMessage}
                generalError={props.generalError}                            
            />            
        </div>
    );

    if (props.jobCanceled || props.transferFinished || props.appInitialized === false) {
        photoBoxes = null;
    }
    return (        
        <div className="container_" id="controlsbox">        
            {photoBoxes}                  
            <Controls 
                systemID={props.systemID}
                svp={props.svp}
                assKey={props.assKey}
                standardPhotos={props.standardPhotos}
                contractData={props.contractData}

                dsgvoAccepted={props.dsgvoAccepted}
                uploadTarget={props.uploadTarget}

                transferFinished={props.transferFinished}
                onTransferFinished={props.onTransferFinished}
                
                takePhotoHandler={props.takePhotoHandler} 
                showPreview={props.showPreview}
                deletePhoto={props.deletePhoto}
                openPhoto={props.openPhoto}
                onAcceptDsgvo={props.onAcceptDsgvo}
                onChangechargeType={props.onChangechargeType}

                vorSchaden={props.vorSchaden}
                onChangeVorschaden={props.onChangeVorschaden}                
                vorschadenAmount={props.vorschadenAmount}
                vorschadenDetail={props.vorschadenDetail}
                
                chargeType={props.chargeType}
                bankData={props.bankData}    
                onError={props.onError}                                            
                errorMessage={props.errorMessage}
                generalError={props.generalError}                            
                error={props.error}     
                onChangeBankData={props.onChangeBankData}
                sendAssignementData={props.sendAssignementData}
                clearAssignementData={props.clearAssignementData}         
                
                modalVisible={props.modalVisible}
                setModalVisible={props.setModalVisible}

                uploadFinished={props.uploadFinished}    
                jobCanceled={props.jobCanceled}    
                isUploading={props.isUploading}                          
                onUploading={props.onUploading}                    
                checkIfUploading={props.checkIfUploading}

                auszahlung_wbw_netto={props.auszahlung_wbw_netto}
                choose_copart={props.choose_copart}
                onChangeData={props.onChangeData}
                customer_mail={props.customer_mail}    
            />            
        </div>
    );
}

export default assignement;