//materials:
//m=MeshToonMaterial
//g=glass
//b=lighting
//r=rubber
//p=plastic
//l=lack

const PartList = {    
    tuer_hinten_l: {"color": "body", "isBody": true, name: "Tür hinten links", "material": "l", "state" : "default"},    
    tuer_hinten_r: {"color": "body", "isBody": true, name: "Tür hinten rechts", "material": "l", "state" : "default"},    
    tuer_vorne_l: {"color": "body", "isBody": true, name: "Tür vorne links", "material": "l", "map": "images/krug_logo_transparent.png", "state" : "default"},
    tuer_vorne_r: {"color": "body", "isBody": true, name: "Tür vorne rechts", "material": "l", "map": "images/krug_logo_transparent.png", "state" : "default"},
    dachflaeche: {"color": "body", "isBody": true, name: "Dachfläche", "material": "l", "state" : "default"},
    dachrahmen_r: {"color": "body", "isBody": true, name: "Dachrahmen rechts", "material": "l", "state" : "default"},
    dachrahmen_l: {"color": "body", "isBody": true, name: "Dachrahmen links", "material": "l", "state" : "default"},
    motorhaube: {"color": "body", "isBody": true, name: "Motorhaube", "material": "l", "map": "images/krug_logo_transparent.png", "state" : "default"},
    kotfluegel_r: {"color": "body", "isBody": true, name: "Kotflügel rechts", "material": "l", "state" : "default"},
    kotfluegel_l: {"color": "body", "isBody": true, name: "Kotflügel links", "material": "l", "state" : "default"},
    seitenwand_l: {"color": "body", "isBody": true, name: "Seitenwand links", "material": "l", "state" : "default"},
    seitenwand_r: {"color": "body", "isBody": true, name: "Seitenwand rechts", "material": "l", "state" : "default"},
    heckklappe: {"color": "body", "isBody": true, name: "Heckklappe", "material": "l", "state" : "default"},
    
    kuehlergrill: {"color": "grill", "isBody": false, name: "Kühlergrill", "material": "m", "state" : "default"},
    stossfaenger_vorne: {"color": "body", "isBody": false, name: "Stoßfänger vorne", "material": "l", "state" : "default"},
    stossfaenger_hinten: {"color": "body", "isBody": false, name: "Stoßfänger hinten", "material": "l", "state" : "default"},

    achsvermessung: {"color": "body", "isBody": false, name: "Achsvermessung", "material": "l", "state" : "default"},


    grundschweller_r: {"color": "body", "isBody": true, name: "Grundschweller rechts", "material": "l", "state" : "default"},
    grundschweller_l: {"color": "body", "isBody": true, name: "Grundschweller links", "material": "l", "state" : "default"},

    frontscheibe: {"color": "glass", "isBody": false, name: "Frontscheibe", "material": "g", "state" : "default"},
    tuerscheibe_vr: {"color": "glass", "isBody": false, name: "Türscheibe vorne rechts", "material": "g", "state" : "default"},
    tuerscheibe_vl: {"color": "glass", "isBody": false, name: "Türscheibe vorne links", "material": "g", "state" : "default"},
    tuerscheibe_hr: {"color": "glass", "isBody": false, name: "Türscheibe hinten rechts", "material": "g", "state" : "default"},
    tuerscheibe_hl: {"color": "glass", "isBody": false, name: "Türscheibe hinten links", "material": "g", "state" : "default"},
    seitenscheibe_hr: {"color": "glass", "isBody": false, name: "Seitenscheibe hinten rechts", "material": "g", "state" : "default"},
    seitenscheibe_hl: {"color": "glass", "isBody": false, name: "Seitenscheibe hinten links", "material": "g", "state" : "default"},
    heckscheibe: {"color": "glass", "isBody": false, name: "Heckscheibe", "material": "g", "state" : "default"},
    schiebedach: {"color": "glass", "isBody": false, name: "Schiebedach", "material": "g", "state" : "default"},
    
    tuergriff_vorne_r: {"color": "body", "isBody": false, name: "Türgriff vorne rechts", "material": "l", "state" : "default"},
    tuergriff_vorne_l: {"color": "body", "isBody": false, name: "Türgriff vorne links", "material": "l", "state" : "default"},
    tuergriff_hinten_r: {"color": "body", "isBody": false, name: "Türgriff hinten rechts", "material": "l", "state" : "default"},
    tuergriff_hinten_l: {"color": "body", "isBody": false, name: "Türgriff hinten links", "material": "l", "state" : "default"},
    aussenspiegel_r: {"color": "body", "isBody": false, name: "Außenspiegel rechts", "material": "l", "state" : "default"},
    aussenspiegel_l: {"color": "body", "isBody": false, name: "Außenspiegel links", "material": "l", "state" : "default"},
    
    rueckleuchte_r: {"color": "backlight", "isBody": false, name: "Rückleuchte rechts", "material": "b", "state" : "default"},
    rueckleuchte_l: {"color": "backlight", "isBody": false, name: "Rückleuchte links", "material": "b", "state" : "default"},
    nebelschlussleuchte_r: {"color": "backlight", "isBody": false, name: "Nebel Schlußleuchte rechts", "material": "b", "state" : "default"},
    nebelschlussleuchte_l: {"color": "backlight", "isBody": false, name: "Nebel Schlußleuchte links", "material": "b", "state" : "default"},
    scheinwerfer_r: {"color": "frontlight", "isBody": false, name: "Scheinwerfer rechts", "material": "b", "state" : "default"},
    scheinwerfer_l: {"color": "frontlight", "isBody": false, name: "Scheinwerfer links", "material": "b", "state" : "default"},
    nebelleuchten_r: {"color": "frontlight", "isBody": false, name: "Nebel Leuchten rechts", "material": "b", "state" : "default"},
    nebelleuchten_l: {"color": "frontlight", "isBody": false, name: "Nebel Leuchten links", "material": "b", "state" : "default"},
    
    radhausschale_vr: {"color": "body", "isBody": false, name: "Radhausschale vorne rechts", "material": "p", "state" : "default"},
    radhausschale_vl: {"color": "body", "isBody": false, name: "Radhausschale vorne links", "material": "p", "state" : "default"},
    radhausschale_hr: {"color": "body", "isBody": false, name: "Radhausschale hinten rechts", "material": "p", "state" : "default"},
    radhausschale_hl: {"color": "body", "isBody": false, name: "Radhausschale hinten links", "material": "p", "state" : "default"},

    reifen_vr: {"color": "wheel", "isBody": false, name: "Reifen vorne rechts", "material": "r", "state" : "default"},
    reifen_vl: {"color": "wheel", "isBody": false, name: "Reifen vorne links", "material": "r", "state" : "default"},
    reifen_hr: {"color": "wheel", "isBody": false, name: "Reifen hinten rechts", "material": "r", "state" : "default"},
    reifen_hl: {"color": "wheel", "isBody": false, name: "Reifen hinten rechts", "material": "r", "state" : "default"},
    felge_vr: {"color": "felge", "isBody": false, name: "Felge vorne rechts", "material": "m", "state" : "default"},
    felge_vl: {"color": "felge", "isBody": false, name: "Felge vorne links", "material": "m", "state" : "default"},
    felge_hr: {"color": "felge", "isBody": false, name: "Felge hinten rechts", "material": "m", "state" : "default"},
    felge_hl: {"color": "felge", "isBody": false, name: "Felge hinten links", "material": "m", "state" : "default"},
}

export default PartList;