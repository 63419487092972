//import React from "react";
import React from 'react';

import PhotoList from "../Photo/PhotoList";
import FileUploader from "../FileUpload/FileUploader";
import Spinner from "react-bootstrap/esm/Spinner";
import MessageModal from '../UI/MessageModal';

import "./Controls.css";
// import { isPropertyAssignment } from "typescript";


const Controls = (props) => {
    
    const onAcceptDsgvo = (event) => {
        const isCheckd = event.target.checked;
        props.onAcceptDsgvo(isCheckd);
    }
    

    let bankAccount = (
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h6>Bitte geben Sie hier Ihre Kontodaten ein, oder fügen Sie mittels der Foto Buttons die Vorder und Rückseite Ihrer Bankkarte ein.</h6>
                </div>
            </div>
            <div className="row">                
                <div className="col-md-2"><label htmlFor="firstname">Vorname:</label></div>
                <div className="col-md-6"><input name="firstname" id="firstname" type="text" className="form-control" defaultValue={props.bankData.firstname} onChange={(event) => onChangeBankData(event)} placeholder="Vorname"></input></div>
            </div>
            <div className="row">
                <div className="col-md-2"><label htmlFor="familyname">Name:</label></div>
                <div className="col-md-6"><input name="familyname" id="familyname" type="text" className="form-control" defaultValue={props.bankData.familyname} onChange={(event) => onChangeBankData(event)} placeholder="Name"></input></div>
            </div>
            <div className="row marginBottom30">
                <div className="col-md-2"><label htmlFor="iban">IBAN:</label></div>
                <div className="col-md-6"><input name="iban" id="iban" type="text" className="form-control" defaultValue={props.bankData.iban} placeholder="IBAN" onChange={(event) => onChangeBankData(event)}></input></div>
            </div>

            <PhotoList 
                type="bank"
                assKey={props.assKey}
                uploadTarget={props.uploadTarget}
                contractData={props.contractData} 
                photos={props.standardPhotos}
                takePhotoHandler={props.takePhotoHandler}
                changeView={props.changeView}
                openPhoto={props.openPhoto}
                showPreview={props.showPreview}
                deletePhoto={props.deletePhoto}
                addDocuments={props.addDocuments}
                documentAddButton={props.documentAddButton}
                isUploading={props.isUploading}
                onUploading={props.onUploading}
                className="checkCardBox"
            />
        </div>
    );    

    const onChangeBankData = (event) => {
        props.onChangeBankData(event.target.name, event.target.value);
    }

    const onChangechargeType = (event) => {
        console.log("onChangechargeType(): ", event.target.name);
        if (event.target.name === "charge_type") {
            let chargeType = event.target.value;
            props.onChangechargeType(event.target.name, chargeType);                                
        }
    };

    
    const onChangeData = (event) => {
        console.log("onChangeData(Controls): ", event.target.name+": "+event.target.value);
        props.onChangeData(event.target.name, event.target.value);  
        //if (event.target.name === "choose_copart") {
        //    props.onChangeData(event.target.name, event.target.value);                                            
        //} else if (event && event.target.name === "customer_mail") {            
        //    props.onChangeData(event.target.name, event.target.value);                                )                                    
        //}
    };

    // const veryfyEmail = (event) => {
    //     props.onChangeData(event.target.name, event.target.value);    
    //     const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;        
    //     if (regexp.test(event.target.value)) {
    //         onChangeData(event);
    //     } else {
    //         console.log("veryfy failed");
    //         props.onChangeData(event.target.name, false);                             
    //     }
    // };

    const onChangeVorschaden = (event) => {
        console.log("onChangeVorschaden(): ", event.target.name);
        let hatvorschaden = event.target.value;
        if (event.target.name === "hatvorschaden") {                        
            props.onChangeVorschaden(event.target.name, hatvorschaden);                                
        } else if (event.target.name === "hatvorschaden_option") {
            props.onChangeVorschaden(event.target.name, hatvorschaden); 
        } else if (event.target.name === "vorschadenamount") {
            props.onChangeVorschaden(event.target.name, hatvorschaden); 
        }
    }

    // const onClickVorschaden = (value) => {                
    //     let hatvorschaden = value;
    //     props.onChangeVorschaden("hatvorschaden", hatvorschaden);                                
    // }
        
    /* const onTransferResult = (result) => {
        console.log("ERROR: ", result);
        if (result.code !== 1) {
            console.log("Error: ", result.message);
        } else {
            console.log("OK: ", result.message);
        }
    } */

    let checkBox = (
        <div>
            <div className="blueLineThin"></div>
            <p className="dsgvoCheckbox">
                <input type="checkbox" defaultChecked={props.dsgvoAccepted} name="datenschutz_ok" onChange={(event) => onAcceptDsgvo(event)}/> Ich habe die <a href="https://sv-krug.de/de/Datenschutz" target="_blank" rel="noopener noreferrer" className="linkToDatenschutz">Datenschutzerklärung</a> gelesen und stimme der Verwendung meiner Daten in diesem Sinne zu
            </p>
        </div>
    )

    let uploadInfo = null;
    if (props.checkIfUploading()) {
        uploadInfo = (
            <div className="isUploading">
                <hr className="endLine" />
                <div className=" row col-md-12 centerBox">
                    <p>Daten Übertragung</p>
                </div>
                <div className=" row col-md-12 centerBox">
                    <Spinner animation="grow"  variant="success" size="sm" />&nbsp;
                    <Spinner  animation="grow" variant="success" />&nbsp;
                    <Spinner animation="grow"  variant="success" size="sm" />                    
                </div>
            </div>
        );
    }
    if (props.generalError) {
        uploadInfo = null;
    }   

    if (props.transferFinished || props.jobCanceled) {
        checkBox = null;
    }

    let sendButton = (
        <div>                    
            {/* <button type="button" disabled={!props.dsgvoAccepted} className="btn btn-sm btn-danger contolButtons" onClick={props.sendAssignementData}>Senden</button> */}
            <FileUploader 
                dsgvoAccepted={props.dsgvoAccepted}
                systemID={props.systemID}
                svp={props.svp}
                code={props.assKey}
                contractData={props.contractData}
                photoList={props.standardPhotos}                
                uploadTarget={props.uploadTarget}
                bankData={props.bankData}
                chargeType={props.chargeType}
                uploadFinished={props.uploadFinished}                
                errorMessage={props.errorMessage}                
                error={props.networkError}     
                clearAssignementData={props.clearAssignementData}                               
                transferFinished={props.transferFinished}
                onTransferFinished={props.onTransferFinished}   
                vorSchaden={props.vorSchaden}     
                vorschadenDetail={props.vorschadenDetail}                
                vorschadenAmount={props.vorschadenAmount} 
                choose_copart={props.choose_copart}      
                customer_mail={props.customer_mail}      
            />                        
        </div>
    );

    if (!props.dsgvoAccepted) {
        //sendButton = null;
    }        
               
    if (!props.uploadFinished && !props.jobCanceled) {
        sendButton = (
            <div>
                <div className="alert alert-danger">
                    Sie müssen zuerst alle noch benötigten Bilder (rot umrandet) aufnehmen.
                </div>
                {uploadInfo}
            </div>
        );
        checkBox = null;            
    } else if (props.jobCanceled) {
        sendButton = (
            <div>
                <div className="alert alert-success">
                    Sie haben den Vorgang erfolgreich abgebrochen, alle von Ihnen übertragenen Daten wurden auf unserem Server gelöscht.
                </div>
                {uploadInfo}
            </div>
        );
        checkBox = null;
    }

    // let contactWay = (
    //     <div>
    //         <h3>gewünschte Kontakt Form</h3>
    //         <select name="contactway">
    //             <option value="t">Telefon</option>
    //             <option value="m">eMail</option>
    //             <option value="s">SMS</option>
    //         </select>
    //         <imput type="text">EMAIL/TELEFON/SMS NUMMER</imput>
    //     </div>
    // );    

    let vorschadenRepaired = (
        <div className="container">            
            <div className="row marginBottom30 marginTop10">                
                <div className="col-md-2"><label htmlFor="vorschadenamount">Schadenbetrag:</label></div>
                <div className="col-md-6"><input name="vorschadenamount" id="vorschadenamount" type="number" className="form-control" 
                    defaultValue={props.bankData.firstname} 
                    onChange={(event) => onChangeVorschaden(event)}
                    placeholder="Betrag in €"></input></div>                    
            </div>    
            <PhotoList 
                    type="vorschaden"
                    assKey={props.assKey}
                    uploadTarget={props.uploadTarget}
                    contractData={props.contractData} 
                    photos={props.standardPhotos}
                    takePhotoHandler={props.takePhotoHandler}
                    changeView={props.changeView}
                    openPhoto={props.openPhoto}
                    showPreview={props.showPreview}
                    deletePhoto={props.deletePhoto}
                    addDocuments={props.addDocuments}
                    documentAddButton={props.documentAddButton}
                    isUploading={props.isUploading}
                    onUploading={props.onUploading}
                    className=""
                />
        </div>
    );
    let vorschadenNotRepaired = (
        <div className="container">                     
            <PhotoList 
                type="vorschaden"
                assKey={props.assKey}
                uploadTarget={props.uploadTarget}
                contractData={props.contractData} 
                photos={props.standardPhotos}
                takePhotoHandler={props.takePhotoHandler}
                changeView={props.changeView}
                openPhoto={props.openPhoto}
                showPreview={props.showPreview}
                deletePhoto={props.deletePhoto}
                addDocuments={props.addDocuments}
                documentAddButton={props.documentAddButton}
                isUploading={props.isUploading}
                onUploading={props.onUploading}
                className=""
            />
        </div>
    );


    let vorSchadenOptions = (
        <div className="container">            
            <label>
                    <input type="radio" checked={props.vorschadenDetail === 0 || props.vorschadenDetail === "0"} name="hatvorschaden_option" value="0" onChange={(event) => onChangeVorschaden(event)}></input>                                                                        
                    &nbsp;&nbsp;<b>Der Schaden wurde bereits Instandgesetzt</b>            
                </label> 
                {props.vorschadenDetail === "0" && vorschadenRepaired}                           
                <br></br>
                <label>
                    <input type="radio" checked={props.vorschadenDetail === 1 || props.vorschadenDetail === "1"} name="hatvorschaden_option" value="1" onChange={(event) => onChangeVorschaden(event)}>
                    </input>&nbsp;&nbsp;<b>Der Schaden wurde nicht repariert</b> 
                </label>
                {props.vorschadenDetail === "1" && vorschadenNotRepaired}                           
        </div>
    );
    

    let vorSchadenBox = (
        <div>
            <div className="controlBoxHeader">&nbsp;</div>            
            <h3>Hatten Sie an Ihrem Fahrzeug schon einmal Vorschäden?</h3>   
                <label>
                    <input type="radio" checked={props.vorSchaden === 0 || props.vorSchaden === "0"} name="hatvorschaden" value="0" onChange={(event) => onChangeVorschaden(event)}></input>                                                                        
                    &nbsp;&nbsp;<b>NEIN</b>, es gab keine Vorschäden
                </label>                  
                <br></br>
                <label>
                    <input type="radio" checked={props.vorSchaden === 1 || props.vorSchaden === "1"} name="hatvorschaden" value="1" onChange={(event) => onChangeVorschaden(event)}>
                    </input>&nbsp;&nbsp;<b>JA</b>, das Fahrzeug hatte Vorschäden
                </label>
                    {props.vorSchaden === "1" && vorSchadenOptions}                                    
                <p></p>
            </div>
    );   
    if (props.jobCanceled)  {
        vorSchadenBox = null;   
    }

    let chargeTypeBox = (
        <div>
            <h3>Abrechnungswunsch:</h3>
            <h4>Wie wollen Sie den Schaden abrechnen?</h4>
                <label>
                    <input type="radio" checked={props.chargeType === "1"} name="charge_type" value="1" onChange={(event) => onChangechargeType(event)}>
                    </input>&nbsp;&nbsp;<b>Fiktiv - Auszahlung</b> 
                </label>
                    <br></br><ol>Bitte zahlen Sie mir das Geld netto aus. Ich wurde darüber informiert, dass eine nachträgliche Reparatur bis zu 12 Monaten möglich ist.</ol>                    
                    {props.chargeType === "1" && bankAccount}
                
                <label>
                    <input type="radio" checked={props.chargeType === "0"} name="charge_type" value="0" onChange={(event) => onChangechargeType(event)}></input>
                    &nbsp;&nbsp;<b>Reparatur</b>
                </label>
                    <br></br><ol>Bitte vermitteln Sie mir eine Reparatur-Werkstatt. Gerne profitiere ich von nachfolgenden Vorteilen:
                    <ul>
                        {/* <li>24-Stunden-Abschleppdienst</li> */}
                        <li>Kostenloser Ersatzwagen</li>
                        <li>Abholen und Rückführen Ihres Fahrzeugs</li>
                        <li>Schnelle Reparatur</li>
                        <li>Instandsetzung mit Original-Ersatzteilen in zertifizierter Fachwerkstatt</li>
                        <li>Reinigung Ihres Fahrzeugs von innen und außen</li>
                        <li>Gültig in ganz Deutschland</li>
                    </ul> 
                    </ol>    
                <label>
                    <input type="radio" checked={props.chargeType === "-1"} name="charge_type" value="-1" onChange={(event) => onChangechargeType(event)}>    
                    </input>&nbsp;&nbsp;<b>ich bin noch unentschlossen</b>
                </label>
                <p></p>
            </div>
    );
    
    if (props.jobCanceled)  {
        chargeTypeBox = null;        
    }
    

    let boxClass = "form-control col-md-3 col-sm-8 ";
    let customer_mail = props.customer_mail;
    let customer_phone = props.customer_phone;
    if (customer_mail === null) {
        customer_mail = "";
    }
    if (customer_phone === null) {
        customer_phone = "";
    }
    if ((customer_mail !== undefined && customer_mail !== "")) {  
        console.log("mail; "+customer_mail);
        const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regexp.test(customer_mail)) {                        
            console.log("email is valid: "+customer_mail);
            boxClass = boxClass+"alert-success";        
        } else {
            boxClass = boxClass+"alert-danger";
        }
    } else {
        //keine email vorhanden
    }
    let contactBox = (        
        <div>
            <h3>Kontakt Daten:</h3>
            <label forname="customer_phone">
                <b>Unter welcher Telefon Nummer können wir Sie bei Fragen erreichen.</b>
            </label>
            <div className="input-group">
                <div className="input-group-prepend">
                    <div className="input-group-text">Telefon</div>
                </div>
                <input type="text" maxLength="80" className={boxClass} name="customer_phone" value={customer_phone} onChange={(event) => onChangeData(event)}></input> 
            </div>
            <p></p>
            <label forname="customer_mail">
                <b>An welche E-Mail-Adresse dürfen wir Ihr Gutachten direkt nach Fertigstellung senden? Damit können wir einen automatischen Versand sicherstellen.</b>
            </label>
            <div className="input-group">
                <div className="input-group-prepend">
                    <div className="input-group-text">@</div>
                </div>
                <input type="text" maxLength="80" className={boxClass} name="customer_mail" value={customer_mail} onChange={(event) => onChangeData(event)}></input> 
            </div>
            <p></p>
        </div>
    );


    let copartBox = (        
        <div>
            <h5>Im Fall eines Totalschadens besteht mit Ihrem Einverständnis die Möglichkeit, dass Sie Ihr Fahrzeug zu dem im Gutachten angegeben Wiederbeschaffungswert verkaufen können. Nutzen Sie den Service Ihrer Versicherung, lassen Sie Ihr Fahrzeug kostenlos abholen und profitieren Sie von einer unbürokratischen und aufwandsarmen Abwicklung.</h5>   
            <label>
                <input type="radio" checked={props.choose_copart === 1 || props.choose_copart === "1"} name="choose_copart" value="1" onChange={(event) => onChangeData(event)}>
                </input>&nbsp;&nbsp;    
            </label>
            <label>
                <input type="radio" checked={props.choose_copart === 0 || props.choose_copart === "0"} name="choose_copart" value="0" onChange={(event) => onChangeData(event)}></input>                                                                        
                &nbsp;&nbsp;<b>NEIN</b>, ich nehme den kostenlosen Service im Falle eines Totalschadens nicht in Anspruch            
            </label>                  
            <br></br>                                     
            <p></p>            
        </div>
    );

    console.log("auszahlung_wbw_netto: "+props.auszahlung_wbw_netto);

    if (!props.auszahlung_wbw_netto || props.auszahlung_wbw_netto === null || props.auszahlung_wbw_netto === "") {
        copartBox = null;
    }

    if (props.transferFinished || props.jobCanceled) {
        chargeTypeBox = null; 
        vorSchadenBox = null;
        copartBox = null;
        contactBox=null;
    }

    

    const popUpText = "Sie haben nun alle erforderlichen Bilder übertragen. Um den Vorgang abzuschließen müssen Sie nun noch das Formular am Ende der Seite bearbeiten und anschliessend den 'Übertragung starten' Button betätigen";    
    
    const ref = React.createRef();
    const setShowModal = (show) => {
        console.log("setShowModal");
        props.setModalVisible();
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    }        
    contactBox= null;
    return (        
        <div className="footerControls" ref={ref}>
            {props.modalVisible && <MessageModal title="Bitte beachten" message={popUpText} buttonLabel="Ok" onConfirm={setShowModal}></MessageModal>}
            {vorSchadenBox}   
            {chargeTypeBox}   
            {contactBox}                    
            {copartBox}                    
            {checkBox}            

            <div className="footerGrid">            
                {/* <div>
                    <button type="button" className="btn btn-sm btn-primary buttonCancel contolButtons" onClick={props.clearAssignementData}>Abbrechen</button>
                    <div>Durch das Betätigen des "Abbrechen" Buttons, werden alle von Ihnen bisher für diesen Vorgang erfassten Dokumente (auch Fotos und Videos) gelöscht</div>
                </div> */}
                {sendButton}
            </div>            
        </div>
    );
}

export default Controls;