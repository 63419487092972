import React from "react";
const Lights = props => {
    return (
        <>
            <spotLight 
                color={[1,1,1]} 
                intensity={0.4} 
                angle={0.8}
                penumbra={0.6} 
                position={[-15, 5, 20]}
                castShadow
                shadowBias={-0.0001} />      
            <spotLight 
                color={[1,1,1]} 
                intensity={0.4} 
                angle={0.8}
                penumbra={0.6} 
                position={[15, 5, 20]}
                castShadow
                shadowBias={-0.0001} />      
            <spotLight 
                color={[1, 1, 1]} 
                intensity={0.4} 
                angle={0.6}
                penumbra={0.5} 
                position={[5, 50, -30]}
                castShadow
                shadowBias={-0.0001} />    
            <ambientLight intensity={0.6} />
            <hemisphereLight intensity={0.5}/>
        </>
    );
}

export default Lights;