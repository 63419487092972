import React from "react";
import "./Layout.css";

const messageHeader = (props) => {
    // let logo = process.env.PUBLIC_URL+"/images/Krug.logo.jpg";
    let kostenlos = <span className="noteLight">(Anruf kostenlos)</span>;
    return (
        <div>
            <div className="container">                
                {/* <div>
                    <img className="AlertBoxLogo" src={logo} alt="SV-Krug GmbH Logo"></img>
                </div> */}
                <div className={"marginTop50 alert alert-warning "+props.messageClass}>
                    {props.message}
                </div>                
            </div>
            <div className="layoutFooter">&nbsp;</div>

            <div className="appFooter">
                <div>&nbsp;</div>
                <div className="appFooterLogoBox">
                    <p className="appFooterHead">Ihr Ansprechpartner:</p>
                    <div >                    
                        <a href="https://www.sv-krug.de" target="_blank" rel="noopener noreferrer"><img  className="appFooterLogo" src={process.env.PUBLIC_URL+"/images/Krug.logo.jpg"} alt="SV-Krug GmbH Logo"></img></a>
                    </div>
                    <p className="appFooterFoot"><a href="tel:+496838986180" className="tapiLink">Telefon: 06838 98618‐0</a></p>                    
                </div>
                <div>&nbsp;</div>                
            </div>
            <hr className="endLine" />
            <div className="endLineControls">                
                <div></div>
                <a href="https://sv-krug.de/de/Impressum" target="_blank" rel="noopener noreferrer" className="mainInfoButton" >Impressum</a>
                <div></div>
                <a href="https://sv-krug.de/de/Datenschutz" target="_blank" rel="noopener noreferrer" className="mainInfoButton">Datenschutzerklärung</a>
                <div></div>
            </div>
        </div>
    )
}

export default messageHeader;